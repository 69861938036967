@use '@angular/material' as mat;

$dark-primary-text: rgba(#000, 0.87);
$light-primary-text: white;
$teal-green-palette: (
  50: #ecf8f2,
  100: #cfefdd,
  200: #b0e4c7,
  300: #90d9b1,
  400: #78d0a0,
  500: #60c88f,
  600: #58c287,
  700: #4ebb7c,
  800: #44b472,
  900: #33a760,
  contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $dark-primary-text,
    400: $dark-primary-text,
    500: $light-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
  ),
);

$typography: mat.define-typography-config(
  $font-family: '"Noto Sans", "Noto Sans JP", "Helvetica Neue", sans-serif',
);
$theme: mat.define-light-theme(
  (
    color: (
      primary: mat.define-palette($teal-green-palette),
      accent: mat.define-palette($teal-green-palette),
      warn: mat.define-palette(mat.$red-palette),
    ),
    typography: $typography,
  )
);

@include mat.core();
@include mat.all-component-themes($theme);

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, 'Helvetica Neue', sans-serif;
}

